const _OPTIONS = {
  teamType: [
    { label: "个人", value: 1, checked: 0, disabled: true },
    { label: "企业", value: 2, checked: 0, disabled: false },
    { label: "政务", value: 3, checked: 0, disabled: false },
    { label: "学校", value: 4, checked: 0, disabled: false },
  ],
  adminRole: [
    { label: "系统", value: 1, checked: 0, disabled: true },
    { label: "团队负责人", value: 2, checked: 0, disabled: false },
    { label: "普通管理员", value: 10, checked: 0, disabled: false },
  ],
  adminLevel: [
    { label: "ROOT", value: 1, checked: 0, disabled: true },
    { label: "系统管理员", value: 2, checked: 0, disabled: true },
    { label: "超级管理员", value: 3, checked: 0, disabled: false },
    { label: "高级管理员", value: 4, checked: 0, disabled: false },
    { label: "普通管理员", value: 5, checked: 1, disabled: false },
    { label: "核销人员", value: 6, checked: 1, disabled: false },
  ],
  status: [
    { label: "正常", value: 1, checked: 1 },
    { label: "回收", value: 2, checked: 0 },
  ],
  searchType: [
    { label: "日期", value: 1, checked: 1 },
    { label: "时间段", value: 2, checked: 0 },
  ],
  articleAttr: [
    { label: "普通", value: 1, checked: 1 },
    { label: "推荐", value: 2, checked: 0 },
    { label: "置顶", value: 3, checked: 0 },
    { label: "头条", value: 4, checked: 0 },
  ],
  clientCheckStatus: [
    { label: "审核中", value: 1, checked: 1 },
    { label: "重新提交资料", value: 2, checked: 0 },
    { label: "通过审核", value: 3, checked: 0 },
  ],
  signStatus: [
    { label: "未确认", value: 0, checked: 0 },
    { label: "未核销", value: 1, checked: 0 },
    { label: "已核销", value: 2, checked: 0 },
  ],
  sex: [
    { label: "未知", value: 0, checked: 0 },
    { label: "男", value: 1, checked: 0 },
    { label: "女", value: 2, checked: 0 },
  ],
  platform: [
    { label: "德易精讲堂", value: 1, checked: 0 },
    { label: "先知院", value: 2, checked: 0 },
    { label: "国学藏易阁", value: 3, checked: 0 },
    { label: "夏氏真传命理", value: 4, checked: 0 },
    { label: "龙虎道", value: 5, checked: 0 },
    { label: "天下易友会", value: 6, checked: 0 },
    { label: "文王易", value: 7, checked: 0 },
    { label: "光明云易堂", value: 8, checked: 0 },
    { label: "三清传道", value: 9, checked: 0 },
    { label: "寻真悟道", value: 10, checked: 0 },
    { label: "伏羲课堂", value: 11, checked: 0 },
    { label: "易学世家", value: 12, checked: 0 },
    { label: "三公真传易", value: 13, checked: 0 },
  ],
};

const _options_map = {};

for (const key in _options_map) {
  const arr = [];
  for (const k in _options_map[key]) {
    arr.push({ label: k, value: _options_map[key][k], checked: 0 });
  }
  _OPTIONS[key] = arr;
}

export { _OPTIONS };
